import React, { useState } from "react";

const formSteps = [
  // Basic Information
  [
    "building_number",
    "unit_number",
    "bedrooms",
    "bathrooms",
    "square_feet",
    "view",
    "unit_type",
    "status",
    "price_or_rent",
    "lease_type",
  ],
  // Address Information
  [
    "street_number",
    "compass_direction",
    "street_name",
    "post_street_direction",
    "city",
    "state",
    "zip_code",
    "zip_plus_four",
    "county",
    "coordinates",
  ],
  // Tax & Property Details
  [
    "real_estate_taxes",
    "real_estate_tax_year",
    "front_door_facing",
    "home_type",
    "lot_size",
    "garage_parking_type",
    "garage_size",
    "driveway_parking_spaces",
    "total_square_footage",
    "year_built",
    "stories_inside_home",
    "master_bedroom_floor",
  ],
  // Features & Amenities
  [
    "pool_type",
    "outdoor_sitting",
    "private_amenities",
    "renovated",
    "renovation_date",
    "waterfront",
    "waterfront_type",
    "waterfront_frontage",
    "boat_access",
    "dock_type",
    "view_type",
    "renovation_type",
  ],
  // Construction Details
  [
    "floor_type",
    "roof_type",
    "roof_age",
    "air_conditioning_type",
    "construction_type",
    "additional_rooms",
    "interior_features",
    "home_exterior_features",
    "appliances",
  ],
];

const enumOptions = {
  compass_direction: ["None", "E", "W", "S", "N", "NE", "NW", "SE", "SW"],
  post_street_direction: ["None", "E", "W", "S", "N", "NE", "NW", "SE", "SW"],
  county: [
    "Martin County",
    "Palm Beach County",
    "Broward County",
    "Miami-Dade County",
    "Monroe County",
  ],
  front_door_facing: ["E", "W", "S", "N", "NE", "NW", "SE", "SW"],
  home_type: ["SFH", "Townhome", "Villa", "Apartment Unit", "Mobile Home"],
  garage_size: ["0", "1-car", "2-car", "3-car", "4-car or more"],
  driveway_parking_spaces: ["0", "1 space", "2 spaces", "3 spaces or more"],
  stories_inside_home: [
    "1-story home",
    "2-story home",
    "3-story home",
    "Home with 4 story or more",
  ],
  master_bedroom_floor: ["First floor", "Upper floors"],
  renovated: ["YES", "NO"],
  waterfront: ["Yes", "No"],
  boat_access: ["YES", "NO"],
  dock_type: ["Private dock", "Community dock", "No docking"],
  roof_type: [
    "Concrete flat tile roof",
    "Concrete barrel tile roof",
    "Clay barrel tile roof",
    "Composite shingle roof",
    "Wood shingle roof",
    "Flat roof",
    "Metal roof",
    "Fiberglass roof",
    "Bahama roof",
    "Other roof",
  ],
};

const setOptions = {
  garage_parking_type: [
    "Attached covered garage",
    "Detached covered garage",
    "Covered carport",
    "Open driveway parking",
    "Street parking",
  ],
  pool_type: [
    "Private indoor pool (not heated)",
    "Private outdoor pool (not heated)",
    "Private indoor heated pool",
    "Private outdoor heated pool",
    "Private jacuzzi/hot tub",
    "Pool is screened in",
    "No private pool",
  ],
  outdoor_sitting: [
    "Open patio",
    "Covered patio",
    "Open balcony",
    "Covered balcony",
    "Covered front porch",
    "Open front porch",
    "No outdoor sitting area",
  ],
  private_amenities: [
    "Private tennis court(s)",
    "Private jacuzzi/hot tub",
    "Private fitness room",
    "Private volleyball court",
    "Private racquetball court",
    "Private basketball court",
    "Private playground",
    "Private barbecue",
    "Private steam room",
    "Private spa",
    "No amenities",
  ],
  view_type: [
    "Garden view",
    "Bay view",
    "Lake view",
    "River view",
    "Canal view",
    "Intracoastal view",
    "Ocean view",
    "Preserve view",
    "Street/Road view",
    "Pool area view",
    "Parking area view",
    "Mountain view",
    "Highway view",
    "Other view",
  ],
  renovation_type: [
    "Fully renovated",
    "Partially renovated",
    "Not Renovated",
    "Kitchen renovated",
    "Master bathroom renovated",
    "Guest bathroom renovated",
    "Living room renovated",
    "Floors replaced",
    "Other",
  ],
  floor_type: [
    "Ceramic tile floors",
    "Porcelain tile floors",
    "Marble floors",
    "Carpeted floors",
    "Hardwood floors",
    "Laminated wood floors",
    "Parquet floors",
    "Concrete floors",
    "Terrazzo floors",
    "Clay floors",
    "Vinyl floors",
    "Other floor",
  ],
  air_conditioning_type: [
    "Wall unit AC",
    "Central AC",
    "Multiple AC units",
  ],
  construction_type: [
    "Aluminum siding",
    "Wood siding",
    "CBS construction",
    "Brick construction",
    "Brick veneer",
    "Pre-cast concrete",
    "Wood frame",
    "Other construction",
  ],
  additional_rooms: [
    "Family room",
    "Den",
    "Utility room",
    "Laundry room",
    "Library/office",
    "Office",
    "Game/media room",
    "Attic",
    "Florida room",
    "Loft",
    "Pantry",
    "Atrium",
    "Maid room",
    "Converted room in garage",
  ],
  interior_features: [
    "Dry bar",
    "Wet bar",
    "Walk in closet(s)",
    "Kitchen island",
    "High ceilings",
    "Elevator",
    "Fireplace",
    "Laundry tub",
    "Pantry",
    "Skylight",
    "Split Bedrooms",
    "Stacked Bedrooms",
    "Impact windows/doors",
    "Other interior features",
    "No interior features",
  ],
  home_exterior_features: [
    "Fence",
    "Wall",
    "Utility shed",
    "Fruit Trees",
    "Solar panels",
    "Stables",
    "Sprinkler system",
    "Other exterior features",
    "No exterior features",
  ],
  appliances: [
    "Refrigerator",
    "Range/Oven",
    "Dishwasher",
    "Microwave",
    "Washer",
    "Dryer",
    "Garbage disposal",
    "Trash compactor",
    "Central vacuum",
    "Intercom",
    "Burglar alarm",
    "Water Softener",
    "Natural gas",
    "Other equipment",
    "No equipment",
  ],
};

const PropertyForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [images, setImages] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked
          ? [...(prev[name] || []), value]
          : (prev[name] || []).filter((item) => item !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  const renderField = (field) => {
    if (enumOptions[field]) {
      return (
        <select
          name={field}
          value={formData[field] || ""}
          onChange={handleChange}
          className="mt-1 p-2 block w-full border rounded-md"
        >
          <option value="">Select</option>
          {enumOptions[field].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    if (setOptions[field]) {
      return (
        <div className="mt-1">
          {setOptions[field].map((option) => (
            <label key={option} className="flex items-center space-x-2">
              <input
                type="checkbox"
                name={field}
                value={option}
                checked={(formData[field] || []).includes(option)}
                onChange={handleChange}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
      );
    }

    return (
      <input
        type="text"
        name={field}
        value={formData[field] || ""}
        onChange={handleChange}
        className="mt-1 p-2 block w-full border rounded-md"
      />
    );
  };

  const nextStep = () => {
    if (currentStep < formSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // 1. Submit property data
      const propertyResponse = await fetch("https://yarcobr-backend.onrender.com/api/properties", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!propertyResponse.ok) {
        throw new Error("Failed to add property");
      }

      const propertyData = await propertyResponse.json();
      const propertyId = propertyData.id; // Assuming the API responds with the new property's ID

      // 2. Upload images
      if (images.length > 0) {
        const formData = new FormData();
        Array.from(images).forEach((file) => formData.append("images", file));

        const imageResponse = await fetch(
          `https://yarcobr-backend.onrender.com/api/images/${propertyId}`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!imageResponse.ok) {
          throw new Error("Failed to upload images");
        }
      }

      alert("Property and images added successfully!");
      setFormData({});
      setImages([]);
      setCurrentStep(0);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while adding the property and images.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Add New Property</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {formSteps[currentStep].map((field) => (
          <div key={field}>
            <label className="block text-sm font-medium capitalize">{field.replace(/_/g, " ")}</label>
            {renderField(field)}
          </div>
        ))}
      </div>
      <div className="mb-4">
          <label className="block text-sm font-medium">Upload property Images</label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            className="mt-1 block w-full"
          />
        </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={prevStep}
          disabled={currentStep === 0}
          className="bg-teal-500 text-white px-4 py-2 rounded"
        >
          Previous
        </button>
        {currentStep === formSteps.length - 1 ? (
          <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-md">
            Submit
          </button>
        ) : (
          <button
            type="button"
            onClick={nextStep}
            className="bg-blue-600 text-white px-4 py-2 rounded-md"
          >
            Next
          </button>
        )}
      </div>
    </form>
  );
};

export default PropertyForm;
