import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/index';
import Admin from './components/Admin'
import MapSearch from "./components/mapsearchpage";

function App() {
  return (
   <Router>
        <div className="font-Poppins bg-white">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/mapsearch" element={<MapSearch />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;
