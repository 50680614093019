import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Property from "./property";
import Logo from "./assets/logo.png";
import PropertyForm from "./newproperty";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("Property");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const renderContent = () => {
    switch (activeTab) {
      case "Property":
        return <Property />;
      case "Account":
        return (
          <div>
            <PropertyForm />
          </div>
        );
      default:
        return <div>Select a tab</div>;
    }
  };

  // Function to handle sidebar toggle
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col h-screen font-Poppins lg:flex-row">
      {/* Sidebar */}
      {/* Overlay for mobile when sidebar is open */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      <aside
        className={`
          fixed 
          top-0 
          left-0 
          h-full 
          bg-red-600 
          text-white 
          shadow-md 
          w-64 
          transform 
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} 
          transition-transform duration-300 
          z-40 
          lg:static 
          lg:translate-x-0 
          lg:w-72
        `}
      >
        {/* Logo and Title and close button on mobile */}
        <div className="flex items-center justify-between lg:justify-center py-6 px-4 lg:px-6">
          <div className="flex items-center">
            <img src={Logo} className="w-12 h-12 object-contain" alt="Logo" />
            <span className="ml-3 text-lg font-semibold hidden lg:inline-block">
              AboutYARCOBR
            </span>
          </div>
          {/* Close button visible only on mobile */}
          <button
            className="lg:hidden text-white focus:outline-none"
            onClick={toggleSidebar}
            aria-label="Close Sidebar"
          >
            <Icon icon="mdi:close" className="text-2xl" />
          </button>
        </div>

        {/* Navigation */}
        <nav className="flex-grow space-y-2 px-2">
          {[
            { name: "Property", icon: "mdi:home" },
            { name: "Account", icon: "mdi:account" },
          ].map(({ name, icon }) => (
            <button
              key={name}
              onClick={() => {
                setActiveTab(name);
                setIsSidebarOpen(false);
              }} // Close sidebar on mobile after selection
              className={`w-full flex items-center gap-4 px-4 py-3 rounded-lg text-sm font-medium transition ${
                activeTab === name
                  ? "bg-teal-500 text-white"
                  : "hover:bg-teal-700 hover:text-white"
              }`}
              aria-current={activeTab === name ? "page" : undefined}
            >
              <Icon icon={icon} className="text-xl" />
              <span className="ml-2">{name}</span>{" "}
              {/* Always show text on all screen sizes */}
            </button>
          ))}
        </nav>

        {/* Logout Button */}
        <button className="mt-auto flex items-center gap-4 px-4 py-3 text-sm font-medium text-gray-200 hover:bg-red-500 hover:text-white transition">
          <Icon icon="mdi:logout" className="text-xl" />
          <span className="ml-2">Logout</span>
        </button>
      </aside>

      {/* Top Navigation Bar for Mobile */}
      <header className="flex items-center justify-between bg-red-600 text-white p-4 lg:hidden">
        <button
          onClick={toggleSidebar}
          className="focus:outline-none"
          aria-label="Open Sidebar"
        >
          <Icon icon="mdi:menu" className="text-2xl" />
        </button>
        <div className="flex items-center">
          <img src={Logo} className="w-8 h-8 object-contain" alt="Logo" />
          <span className="ml-2 text-lg font-semibold">AboutYARCOBR</span>
        </div>
        {/* Placeholder for alignment */}
        <div className="w-8 h-8"></div>
      </header>

      {/* Main Content */}
      <main className="flex-1 p-4 bg-gray-100 overflow-auto">
        <section className="p-6 rounded-lg">{renderContent()}</section>
      </main>
    </div>
  );
};

export default Dashboard;
